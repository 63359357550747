<script setup lang="ts">
import { createSelectOptions, MAP_SEARCH_FILTER_TYPES, type SearchFilters } from "@gasparigit/omnia-sdk";
import { InfoIcon } from "lucide-vue-next";
import { AREAS_MAP } from "~/lib/const";
import { defaultAdvancedOptions, defaultFilters, type AdvancedOptions } from "~/lib/utils/search";

const { data: loggedUser } = await useLoggedUser();

const props = withDefaults(
  defineProps<{
    showStatistics?: boolean;
    initialFilters?: SearchFilters;
    advancedOptions?: AdvancedOptions;
    placeholder?: string;
    submit?: boolean;
    showFilterButton?: boolean;
  }>(),
  {
    showStatistics: true,
    placeholder: "Cerca con OmnIA",
    submit: true,
    showFilterButton: false,
  }
);

const emit = defineEmits(["submit"]);

const advancedOptions = defineModel<AdvancedOptions>("options", {
  default: defaultAdvancedOptions,
});

watch(
  () => props.advancedOptions,
  (newAdvancedOptions) => {
    advancedOptions.value = newAdvancedOptions ? { ...defaultAdvancedOptions, ...newAdvancedOptions } : { ...defaultAdvancedOptions };
  },
  { immediate: true }
);

const filters = defineModel<SearchFilters>("filters", {
  default: defaultFilters,
});

watch(
  () => props.initialFilters,
  (newInitialFilters) => {
    filters.value = newInitialFilters ? { ...defaultFilters, ...newInitialFilters } : { ...defaultFilters };
  },
  { immediate: true }
);

const query = defineModel<string>("query", { default: "" });

const omniaSdk = useOmniaSdk();

const { data: count } = useAsyncData("contents-count", async () => {
  try {
    if (!props.showStatistics) return null;

    return await omniaSdk.v1.contents.count();
  } catch (error) {}
});

const { data: areas } = useAsyncData("all-areas", async () => {
  try {
    return await omniaSdk.v1.areas.list({ pagination: "none" });
  } catch (error) {}
});

const popoverOpen = ref(false);
const popover = ref<HTMLElement | null>(null);

function onClickInput(e: Event) {
  popoverOpen.value = true;

  nextTick(() => {
    const target = e.target as HTMLElement;
    if (target.tagName === "INPUT") (target as HTMLInputElement).focus();
  });
}

function onSearch() {
  if (!query.value.trim().length) return;

  popoverOpen.value = false;

  emit("submit");

  if (props.submit) {
    nextTick(() => {
      navigateTo({
        path: "/cerca",
        query: {
          q: query.value,
          filters: JSON.stringify(filters.value),
          options: JSON.stringify(advancedOptions.value),
        },
      });
    });
  }
}
</script>

<template>
  <div>
    <Form @submit="onSearch">
      <Popover v-model:open="popoverOpen" class="w-full">
        <PopoverTrigger as-child>
          <div class="flex gap-2 items-center" @click="onClickInput">
            <FormField name="q" v-slot="{ componentField }">
              <FormItem class="w-full">
                <FormControl>
                  <div class="relative w-full items-center flex-1">
                    <Input
                      name="q"
                      v-bind="componentField"
                      type="text"
                      class="text-lg placeholder:text-themeGray px-14 pr-20 py-6 border-0 border-b rounded-md outline-none text-foreground border-none"
                      autocomplete="off"
                      :placeholder="placeholder"
                      v-model="query"
                    />
                    <span class="absolute start-0 text-themeGray inset-y-0 flex items-center justify-center px-2">
                      <Icon name="circum:search" class="me-2 h-9 w-9" />
                    </span>
                    <button type="button" @click="popoverOpen = true" class="absolute end-0 text-themeGray inset-y-0 flex items-center justify-center px-2">
                      <LogoIA class="ms-2 size-16" />
                      <span class="sr-only">Mostra filtri</span>
                    </button>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            </FormField>
            <Button type="button" v-if="showFilterButton" class="gap-1">
              <IconsFilter class="size-4" />
              <span class="sr-only md:not-sr-only !whitespace-nowrap">Applica filtri</span>
            </Button>
          </div>
          <input type="hidden" name="filters" :value="JSON.stringify(filters)" v-if="filters" />
          <input type="hidden" name="options" :value="JSON.stringify(advancedOptions)" v-if="advancedOptions" />
        </PopoverTrigger>
        <PopoverContent class="rounded-lg border-none" style="width: var(--radix-popover-trigger-width)" align="start" side="bottom" :avoid-collisions="false" :side-offset="10">
          <Form @submit="onSearch">
            <input type="hidden" name="filters" :value="JSON.stringify(filters)" v-if="filters" />
            <input type="hidden" name="options" :value="JSON.stringify(advancedOptions)" v-if="advancedOptions" />
            <input type="hidden" name="q" :value="query" />

            <div class="mb-5 py-2 bg-primary-50 text-primary-950 text-sm rounded-md border border-primary-100 flex items-center gap-2 px-2">
              <InfoIcon class="w-4 h-4 flex-shrink-0" />
              <p>Ti consigliamo di applicare i seguenti filtri per dei risultati più accurati</p>
            </div>

            <div class="divide-y divide-purple-200 space-y-4 [&>*]:pt-4 [&>*:first-child]:pt-0" ref="popover">
              <FrontCheckboxChips
                v-if="!initialFilters?.area?.length"
                title="Filtra per area"
                name="area"
                base-key="area"
                :options="
                  areas?.data.map((area) => ({
                    label: area.title,
                    value: area.slug,
                    color: `${AREAS_MAP[area.id].color}-foreground`,
                  }))
                "
                :model-value="filters.area"
                @update:model-value="(f) => (filters = { ...filters, area: f })"
              />
              <FrontCheckboxChips
                v-if="!initialFilters?.front_content_type?.length"
                title="Filtra per prodotto"
                name="front_content_type"
                base-key="front_content_type"
                :options="createSelectOptions(MAP_SEARCH_FILTER_TYPES)"
                :model-value="filters.front_content_type"
                @update:model-value="
                  (f) =>
                    (filters = {
                      ...filters,
                      front_content_type: f,
                    })
                "
              />
              <div class="space-y-3 text-base">
                <h6 class="font-semibold">Impostazioni IA</h6>
                <div class="flex flex-wrap gap-1" v-if="loggedUser">
                  <FrontAISettingChip
                    name="query"
                    :checked="advancedOptions.query"
                    @update:checked="
                      (c) =>
                        (advancedOptions = {
                          ...advancedOptions,
                          query: c,
                        })
                    "
                    title="Migliora query"
                    description="Trasforma la query di ricerca includendo sinonimi e parole chiave"
                  />
                  <FrontAISettingChip
                    name="has_filters"
                    :checked="advancedOptions.filters"
                    @update:checked="
                      (c) =>
                        (advancedOptions = {
                          ...advancedOptions,
                          filters: c,
                        })
                    "
                    title="Filtri automatici"
                    description='Aggiungi i filtri specificati nella query di ricerca (es. "scadenze di oggi" applicherà il filtro "Prodotto" e il filtro "Data")'
                  />
                  <FrontAISettingChip
                    name="has_answer"
                    :checked="advancedOptions.answer"
                    @update:checked="
                      (c) =>
                        (advancedOptions = {
                          ...advancedOptions,
                          answer: c,
                        })
                    "
                    title="Panoramica IA"
                    description="Genera una panoramica per la query di ricerca"
                  />
                  <FrontAISettingChip
                    name="has_related"
                    :checked="advancedOptions.related"
                    @update:checked="
                      (c) =>
                        (advancedOptions = {
                          ...advancedOptions,
                          related: c,
                        })
                    "
                    title="Genera correlate"
                    description="Genera una serie di query di ricerca che potrebbero interessarti"
                  />
                </div>
                <div v-else>
                  <p class="text-muted-foreground">Le impostazioni IA sono disponibili solo per gli utenti abbonati</p>
                </div>
              </div>
              <div class="flex w-full">
                <Button type="submit" class="w-full">Cerca</Button>
              </div>
            </div>
          </Form>
        </PopoverContent>
      </Popover>
    </Form>
  </div>

  <div class="flex flex-wrap justify-around text-primary text-base mt-4" v-if="showStatistics">
    <p class="flex items-center px-1">
      <Icon name="carbon:document-blank" class="h-5 w-5 me-2" />
      <span class="font-bold me-1">{{ count?.modello }}</span> modelli
    </p>
    <p class="flex items-center px-1">
      <Icon name="carbon:calendar" class="h-5 w-5 me-2" />
      <span class="font-bold me-1">{{ count?.scadenza }}</span> scadenze
    </p>
    <p class="flex items-center px-1">
      <Icon name="carbon:chat" class="h-5 w-5 me-2" />
      <span class="font-bold me-1">{{ count?.quesito }}</span> quesiti
    </p>
    <p class="flex items-center px-1">
      <Icon name="carbon:bookmark" class="h-5 w-5 me-2" />
      <span class="font-bold me-1">{{ count?.notizia }}</span> notizie
    </p>
  </div>
</template>
