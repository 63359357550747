<script setup lang="ts">
type FilterOption = {
  label: unknown;
  value: any;
  color?: string;
};

withDefaults(
  defineProps<{
    baseKey: string;
    name?: string;
    title?: string;
    options: FilterOption[];
    defaults?: any[];
    description?: string;
  }>(),
  {
    title: "Filtra",
  }
);

const selected = defineModel<any[] | null>();
</script>

<template>
  <FormField :name="name || baseKey">
    <FormItem class="space-y-3 text-base">
      <FormLabel class="font-semibold text-base">{{ title }}</FormLabel>

      <div class="flex flex-wrap gap-1">
        <FrontCheckboxChip
          v-for="option in options"
          :name="name || baseKey"
          :key="`${baseKey}-${option.value}`"
          :title="String(option.label)"
          :native-value="option.value"
          :color="option.color"
          :checked="selected?.includes(option.value)"
          @update:checked="
            (c) => {
              if (c) selected = [...(selected || []), option.value];
              else selected = [...(selected?.filter((t) => t != option.value) || [])];
            }
          "
        />
      </div>
    </FormItem>
  </FormField>
</template>
