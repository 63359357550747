<script setup lang="ts">
import { cn } from "~/lib/utils";

withDefaults(
  defineProps<{
    name: string;
    title: string;
    color?: string;
  }>(),
  {
    color: "primary-600",
  }
);

const checked = defineModel<boolean>("checked", { default: false });

function toggle() {
  checked.value = !checked.value;
}
</script>

<template>
  <FormField :name="name" v-slot="{ componentField }">
    <FormItem class="space-y-0">
      <FormControl>
        <Checkbox class="hidden" v-bind="{ ...$props, componentField }" v-model:checked="checked" />
      </FormControl>
      <button type="button" class="flex gap-1 items-center" @keydown.enter.prevent="toggle()" @keydown.space.prevent="toggle()">
        <FormLabel class="font-normal text-nowrap cursor-pointer">
          <div class="p-2 rounded-full font-semibold border select-none" :class="cn(checked ? `bg-${color} border-${color} text-white` : 'bg-neutral-50 border-border')">
            {{ title }}
          </div>
        </FormLabel>
      </button>
    </FormItem>
  </FormField>
</template>
