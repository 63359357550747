<script setup lang="ts"></script>

<template>
  <svg v-bind="$attrs" viewBox="0 0 265 89" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.0187 30.448C11.0187 33.6427 11.4107 36.5453 12.196 39.1533C12.9813 41.76 14.116 43.976 15.6027 45.7973C17.088 47.62 18.896 49.036 21.028 50.0453C23.1587 51.0547 25.5707 51.56 28.2613 51.56C30.896 51.56 33.28 51.0547 35.4107 50.0453C37.5413 49.036 39.3493 47.62 40.836 45.7973C42.3213 43.976 43.4573 41.76 44.2427 39.1533C45.0267 36.5453 45.42 33.6427 45.42 30.448C45.42 27.252 45.0267 24.3493 44.2427 21.7413C43.4573 19.1347 42.3213 16.92 40.836 15.0973C39.3493 13.276 37.5413 11.8587 35.4107 10.8493C33.28 9.84132 30.896 9.33466 28.2613 9.33466C25.5707 9.33466 23.1587 9.84132 21.028 10.8493C18.896 11.8587 17.088 13.2893 15.6027 15.1387C14.116 16.9907 12.9813 19.2053 12.196 21.784C11.4107 24.364 11.0187 27.252 11.0187 30.448ZM56.5227 30.448C56.5227 35.4387 55.7787 39.8413 54.2933 43.652C52.808 47.4667 50.7893 50.648 48.2373 53.2C45.6853 55.752 42.7 57.672 39.28 58.9613C35.8587 60.2507 32.2147 60.8947 28.3453 60.8947C24.476 60.8947 20.8173 60.2507 17.3693 58.9613C13.9213 57.672 10.92 55.752 8.36933 53.2C5.81733 50.648 3.78533 47.4667 2.27067 43.652C0.757333 39.8413 0 35.4387 0 30.448C0 25.4573 0.757333 21.0693 2.27067 17.284C3.78533 13.4987 5.84533 10.3173 8.45333 7.73732C11.0613 5.15866 14.0747 3.22399 17.496 1.93332C20.9147 0.643989 24.532 -6.54292e-06 28.3453 -6.54292e-06C32.1587 -6.54292e-06 35.7747 0.643989 39.196 1.93332C42.616 3.22399 45.6013 5.15866 48.1533 7.73732C50.704 10.3173 52.7373 13.4987 54.252 17.284C55.7653 21.0693 56.5227 25.4573 56.5227 30.448Z"
      fill="#F2983A"
    />
    <path
      d="M76.6664 1.34507C77.8997 3.47574 79.2744 6.08374 80.7878 9.16641C82.3024 12.2531 83.8438 15.4904 85.4144 18.8824C86.9838 22.2757 88.5398 25.7237 90.0824 29.2277C91.6238 32.7331 93.0398 35.9704 94.3304 38.9424C95.6198 35.9704 97.0211 32.7331 98.5358 29.2277C100.049 25.7237 101.59 22.2757 103.161 18.8824C104.73 15.4904 106.274 12.2531 107.788 9.16641C109.301 6.08374 110.676 3.47574 111.909 1.34507H121.458C121.972 5.60774 122.457 10.1344 122.914 14.9291C123.37 19.7237 123.785 24.6304 124.156 29.6491C124.525 34.6677 124.869 39.7144 125.182 44.7877C125.497 49.8637 125.769 54.8117 125.997 59.6344H115.568C115.34 52.8504 115.068 45.8691 114.753 38.6904C114.438 31.5131 113.968 24.5597 113.34 17.8304C112.721 19.1211 111.993 20.6624 111.153 22.4571C110.31 24.2517 109.413 26.1997 108.461 28.3024C107.506 30.4051 106.538 32.5797 105.558 34.8211C104.577 37.0651 103.624 39.2371 102.7 41.3397C101.774 43.4424 100.918 45.4064 100.133 47.2277C99.3477 49.0504 98.6744 50.6064 98.1144 51.8957H90.2077C89.6477 50.6064 88.9744 49.0357 88.1904 47.1851C87.4037 45.3357 86.5491 43.3584 85.6251 41.2557C84.6997 39.1531 83.7451 36.9811 82.7651 34.7371C81.7838 32.4957 80.8158 30.3357 79.8624 28.2611C78.9091 26.1864 78.0117 24.2384 77.1717 22.4157C76.3304 20.5931 75.6011 19.0651 74.9837 17.8304C74.3544 24.5597 73.8837 31.5131 73.5691 38.6904C73.2544 45.8691 72.9851 52.8504 72.7557 59.6344H62.3264C62.5544 54.8117 62.8251 49.8224 63.1397 44.6624C63.4531 39.5051 63.7971 34.3877 64.1664 29.3117C64.5371 24.2384 64.9517 19.3171 65.4091 14.5504C65.8651 9.78507 66.3504 5.3824 66.8651 1.34507H76.6664Z"
      fill="#F2983A"
    />
    <path
      d="M173.352 59.6339C171.445 56.4379 169.314 53.0032 166.96 49.3312C164.604 45.6579 162.165 41.9579 159.641 38.2272C157.118 34.4992 154.553 30.8819 151.945 27.3779C149.338 23.8739 146.857 20.7192 144.501 17.9152V59.6339H134.072V1.34458H142.736C144.977 3.70058 147.389 6.48992 149.969 9.71392C152.548 12.9392 155.141 16.2899 157.749 19.7646C160.356 23.2432 162.88 26.7339 165.318 30.2366C167.758 33.7419 169.93 37.0072 171.837 40.0352V1.34458H182.352V59.6339H173.352Z"
      fill="#F2983A"
    />
    <path
      d="M250.112 84.6236C250.575 84.6236 251.016 84.6131 251.437 84.5921C251.858 84.5711 252.194 84.5395 252.446 84.4975V80.9331C252.257 80.8911 251.973 80.849 251.595 80.807C251.216 80.7649 250.869 80.7439 250.554 80.7439C250.112 80.7439 249.692 80.7754 249.292 80.8385C248.914 80.8806 248.577 80.9752 248.283 81.1224C247.988 81.2696 247.757 81.4694 247.589 81.7217C247.421 81.974 247.336 82.2895 247.336 82.668C247.336 83.404 247.578 83.9192 248.062 84.2136C248.567 84.4869 249.25 84.6236 250.112 84.6236ZM249.734 70.871C251.122 70.871 252.278 71.0287 253.203 71.3441C254.129 71.6595 254.865 72.1117 255.411 72.7005C255.979 73.2893 256.379 74.0042 256.61 74.8454C256.841 75.6865 256.957 76.6223 256.957 77.6527V87.4309C256.284 87.5781 255.348 87.7464 254.15 87.9356C252.951 88.1459 251.5 88.2511 249.797 88.2511C248.724 88.2511 247.746 88.1564 246.863 87.9672C246.001 87.7779 245.255 87.473 244.624 87.0524C243.993 86.6108 243.509 86.0431 243.173 85.3491C242.836 84.6552 242.668 83.8035 242.668 82.7942C242.668 81.8268 242.857 81.0067 243.236 80.3338C243.635 79.6609 244.161 79.1247 244.813 78.7251C245.465 78.3256 246.211 78.0417 247.053 77.8735C247.894 77.6842 248.766 77.5896 249.671 77.5896C250.28 77.5896 250.817 77.6211 251.279 77.6842C251.763 77.7263 252.152 77.7894 252.446 77.8735V77.4319C252.446 76.6328 252.205 75.9914 251.721 75.5078C251.237 75.0241 250.396 74.7823 249.197 74.7823C248.398 74.7823 247.61 74.8454 246.832 74.9715C246.054 75.0767 245.381 75.2344 244.813 75.4447L244.214 71.6595C244.487 71.5754 244.823 71.4913 245.223 71.4072C245.644 71.3021 246.096 71.2179 246.579 71.1549C247.063 71.0707 247.568 71.0077 248.093 70.9656C248.64 70.9025 249.187 70.871 249.734 70.871Z"
      fill="#6983A3"
    />
    <path
      d="M230.215 67.1805L234.915 66.4235V71.3126H240.561V75.2239H234.915V81.0593C234.915 82.0477 235.083 82.8362 235.419 83.425C235.777 84.0138 236.481 84.3082 237.533 84.3082C238.037 84.3082 238.553 84.2662 239.078 84.1821C239.625 84.0769 240.119 83.9402 240.561 83.772L241.223 87.431C240.655 87.6623 240.025 87.8621 239.331 88.0303C238.637 88.1985 237.785 88.2826 236.776 88.2826C235.493 88.2826 234.431 88.1144 233.59 87.7779C232.749 87.4205 232.076 86.9368 231.571 86.327C231.066 85.6961 230.709 84.9391 230.499 84.0559C230.309 83.1727 230.215 82.1949 230.215 81.1224V67.1805Z"
      fill="#6983A3"
    />
    <path
      d="M211.163 79.7345C211.163 78.2625 211.384 76.9798 211.826 75.8863C212.288 74.7718 212.888 73.8465 213.624 73.1105C214.36 72.3745 215.201 71.8173 216.147 71.4387C217.115 71.0602 218.103 70.871 219.112 70.871C221.467 70.871 223.328 71.5965 224.695 73.0474C226.062 74.4774 226.746 76.5907 226.746 79.3875C226.746 79.6609 226.735 79.9658 226.714 80.3023C226.693 80.6177 226.672 80.9016 226.651 81.1539H215.99C216.095 82.1212 216.547 82.8888 217.346 83.4565C218.145 84.0243 219.217 84.3082 220.563 84.3082C221.425 84.3082 222.267 84.2346 223.087 84.0874C223.928 83.9192 224.611 83.7194 225.137 83.4881L225.768 87.3048C225.515 87.4309 225.179 87.5571 224.758 87.6833C224.338 87.8095 223.865 87.9146 223.339 87.9987C222.834 88.1039 222.288 88.188 221.699 88.2511C221.11 88.3141 220.521 88.3457 219.932 88.3457C218.439 88.3457 217.136 88.1249 216.021 87.6833C214.928 87.2417 214.013 86.6424 213.277 85.8853C212.562 85.1073 212.026 84.1925 211.668 83.1411C211.332 82.0897 211.163 80.9542 211.163 79.7345ZM222.203 77.9366C222.182 77.537 222.109 77.148 221.983 76.7695C221.877 76.391 221.699 76.0545 221.446 75.7601C221.215 75.4657 220.91 75.2239 220.532 75.0346C220.174 74.8454 219.722 74.7507 219.175 74.7507C218.65 74.7507 218.197 74.8454 217.819 75.0346C217.44 75.2028 217.125 75.4342 216.873 75.7286C216.62 76.023 216.421 76.3699 216.273 76.7695C216.147 77.148 216.053 77.537 215.99 77.9366H222.203Z"
      fill="#6983A3"
    />
    <path
      d="M203.545 79.4822C203.545 76.4541 202.431 74.94 200.202 74.94C199.718 74.94 199.235 75.0031 198.751 75.1293C198.288 75.2554 197.91 75.4131 197.615 75.6024V84.1505C197.847 84.1926 198.141 84.2346 198.499 84.2767C198.856 84.2977 199.245 84.3082 199.666 84.3082C200.948 84.3082 201.916 83.8666 202.568 82.9834C203.219 82.1002 203.545 80.9331 203.545 79.4822ZM208.34 79.6083C208.34 80.9331 208.14 82.1318 207.741 83.2042C207.362 84.2767 206.805 85.1914 206.069 85.9484C205.333 86.7055 204.429 87.2943 203.356 87.7148C202.284 88.1144 201.064 88.3141 199.697 88.3141C199.129 88.3141 198.53 88.2826 197.899 88.2195C197.289 88.1775 196.68 88.1144 196.07 88.0303C195.481 87.9461 194.913 87.8515 194.366 87.7464C193.82 87.6202 193.336 87.494 192.915 87.3679V64.1839L197.615 63.4269V71.6911C198.141 71.4598 198.688 71.281 199.256 71.1549C199.823 71.0287 200.433 70.9656 201.085 70.9656C202.263 70.9656 203.304 71.1759 204.208 71.5965C205.112 71.996 205.869 72.5743 206.479 73.3313C207.089 74.0883 207.551 75.0031 207.867 76.0755C208.182 77.127 208.34 78.3046 208.34 79.6083Z"
      fill="#6983A3"
    />
    <path d="M193.441 1.34509H204.039V59.6344H193.441V1.34509Z" fill="#529AD3" />
    <path
      d="M235.37 12.6157C234.079 15.5891 232.607 19.2064 230.954 23.4664C229.299 27.7291 227.575 32.3544 225.78 37.3451H244.958C243.163 32.3544 241.426 27.6997 239.743 23.3824C238.06 19.0651 236.602 15.4757 235.37 12.6157ZM252.571 59.6344C251.783 57.3357 250.968 55.0651 250.124 52.8211C249.28 50.5784 248.466 48.2797 247.679 45.9237H223.059C222.272 48.2797 221.47 50.5917 220.655 52.8637C219.84 55.1344 219.039 57.3917 218.254 59.6344H207.232C209.416 53.4091 211.49 47.6624 213.451 42.3917C215.411 37.1211 217.33 32.1304 219.207 27.4197C221.084 22.7091 222.946 18.2237 224.795 13.9624C226.644 9.70107 228.55 5.49574 230.511 1.34507H240.628C242.59 5.49574 244.496 9.70107 246.347 13.9624C248.198 18.2237 250.062 22.7091 251.94 27.4197C253.82 32.1304 255.755 37.1211 257.746 42.3917C259.735 47.6624 261.824 53.4091 264.012 59.6344H252.571Z"
      fill="#529AD3"
    />
  </svg>
</template>
