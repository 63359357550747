import type { SearchFilters } from "@gasparigit/omnia-sdk";

export type AdvancedOptions = {
  filters: boolean;
  query: boolean;
  answer: boolean;
  related: boolean;
};

export const defaultFilters: SearchFilters = {
  front_content_type: [],
  area: [],
  from: null,
  to: null,
};

export const defaultAdvancedOptions = {
  filters: true,
  query: true,
  answer: false,
  related: false,
} as AdvancedOptions;
