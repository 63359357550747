<script setup lang="ts">
import { Info } from "lucide-vue-next";

defineProps<{
  name: string;
  title: string;
  description: string;
}>();

const checked = defineModel<boolean>("checked", { default: false });

function toggle() {
  checked.value = !checked.value;
}
</script>

<template>
  <FormField :name="name" v-slot="{ componentField }">
    <FormItem class="space-y-0">
      <FormControl>
        <Checkbox class="hidden" v-bind="{ ...$props, componentField }" v-model:checked="checked" />
      </FormControl>
      <button type="button" @keydown.enter.prevent="toggle()" @keydown.space.prevent="toggle()">
        <FormLabel class="font-normal text-nowrap cursor-pointer">
          <div class="rounded-full p-[2px] bg-gradient-to-br from-primary-600 via-purple-500 to-tertiary-400">
            <div
              class="p-2 rounded-full font-semibold select-none flex gap-1 items-center"
              :class="checked ? 'bg-gradient-to-br from-primary-600 via-purple-500 to-tertiary-400 text-white' : 'bg-white'"
            >
              {{ title }}
              <Popover>
                <PopoverTrigger as-child @click.prevent="">
                  <div>
                    <Info class="size-4 text-muted-foreground" :class="checked && 'text-white/80'" />
                    <span class="sr-only">Maggiori informazioni</span>
                  </div>
                </PopoverTrigger>
                <PopoverContent>
                  <FormDescription>{{ description }}</FormDescription>
                </PopoverContent>
              </Popover>
            </div>
          </div>
        </FormLabel>
      </button>
    </FormItem>
  </FormField>
</template>
